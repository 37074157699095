import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gold in the Cave</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_gold.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Gold in the Cave</h1>
          <h2>Everything you need to know about the Gold in the Cave Raid!</h2>
          <p>
            Last updated: <strong>19/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <StaticImage
        src="../../../images/reverse/generic/boss_1.jpg"
        alt="Raid Boss"
      />
      <p>
        This goofy goober we call <strong>“Gold In The Cave”</strong> (from here
        on out, just “Gold”) and it’s the third boss of the 1.4 Mane’s Bulletin.
        It isn’t seen anywhere else. Its Afflatus is Spirit, so the standard is
        to run any type of team into it, though 6 does absolutely excel at this
        fight due to his Afflatus Advantage.
      </p>
      <ul>
        <li>
          Allies gain +30% DMG Dealt and heal the lower HP% ally for 10% Max HP
          after casting an Ultimate.{' '}
          <strong>
            So utilizing characters that can cast their Ultimate often will help
            you pump more damage as well as stay alive
          </strong>
          .
        </li>
        <li>
          <strong>
            Allies gain +1 Moxie and 20% DMG Heal for 2 rounds every round if
            the boss has at least 2 [Stats Down], [Neg Status]
          </strong>{' '}
          or if mobs are under the effect of [Control], which greatly benefits a
          number of debuff Supports and especially 6, who has Afflatus advantage
          as well as debuffs.
        </li>
        <li>
          When Gold isn’t under the effect of [Stats Down] or [Neg Status] its
          DMG Dealt is increased by 15%, and if it has 3 or less of them its Pen
          Rate is also increased by 15%. This encourages the player to inflict
          debuffs frequently and often, so look for characters with consistent
          cards that apply these.
        </li>
        <li>
          <strong>
            Before being attacked by an Ultimate, Gold loses its unique buffs
          </strong>
          , further enhancing the useability of characters that can cast their
          Ultimate frequently.
        </li>
        <li>
          If any of Gold’s unique buffs reach 5 stacks, their [Disarm] effect is
          extended to 2 rounds instead of 1.
        </li>
        <li>
          In Endless Mode, at the end of the round, Gold inflicts a layer of
          [Dispersion] on all enemies. This is a layered debuff that decreases
          their Incantation Might, and the layers only disappear when the
          character casts an Ultimate.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <div className="team-box">
        <h5 className="beast">MELANIA/KAALAA TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="melania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kaalaa-baunaa"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="a-knight"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="shamane"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="bkornblume"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="baby-blue"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="necrologist"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>Any other healer.</p>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This team focused around both Melania and Kaalaa Baunaa, though the
            latter can be swapped out for characters such as A Knight. This
            composition capitalizes on 6’s release and excellent usage in this
            fight to, in combination with a set of powerful Carry characters,
            rush the boss into the high tiers.
          </p>
          <ul>
            <li>
              Melania is the queen of Mane’s Bulletin, with a kit that perfectly
              synergizes with the game mode’s extended single-target fights with
              high amounts of HP. She can quickly ramp her [Fixed Plan],
              stacking up to turn her Ultimate into a fast-charging nuke.
            </li>
            <li>
              Joining in the ramping game is Kaalaa Baunaa, who utilizes the
              extra AP to rapidly cycle her own Ultimate through her actions,
              quickly stacking up [Full Moon] to provide herself with even more
              damage and even a way to upgrade her own cards with [Incantation
              Empowerment II].
            </li>
            <li>
              6 can be used in pretty much every team due to the versatility of
              his buffs, debuffs and access to semi-on-demand [Incantation
              Empowerment] for your Carry. This especially helps in this fight,
              as frequently casting Ultimates on the boss keeps their buffs (and
              damage) lowered. In addition, his sheer application of debuffs
              allow him to solo most of the mechanics in this fight.
            </li>
            <li>
              A mighty surprising option for the healer slot, Necrologist
              provides the biggest +DMG Dealt buff in the game at Portray 1
              (40%) and 5 (50%) and with the extra AP in 4-man content. In this
              fight specifically, where Crit isn’t necessarily needed,
              Necrologist’s [Prayer] buff and dispel helps keep this team afloat
              in the later stages of Endless Mode.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>A Knight</strong> - With the release of the “Balance,
              Please” psychube, A Knight enjoyed a great upgrade to his damage
              output. In addition, with this boss having ads and 6 commonly in
              its comp, A Knight is one of the few characters who can ramp their
              Ultimate extremely quickly while providing extra support in the
              form of his [Rousing Morale] on-kill.
            </li>
            <li>
              <strong>Shamane</strong> - In case you’re lacking 6 and a
              consistent debuffer, Shamane can accomplish much the same with his
              consistent debuffing and generous single-target output. In
              addition, his [Nature’s Blessing] effect synergizes with the
              mechanics of this fight that require you to cast your Ultimate
              often.
            </li>
            <li>
              <strong>Bkornblume</strong> - For much the same reason that
              Shamane is effective, Bkornblume is effective (especially when
              running A Knight). She doesn't get access to her Ultimate as
              frequently as Shamane does, so there’s that to take into
              consideration.
            </li>
            <li>
              <strong>Tooth Fairy</strong> - Surprisingly not used over
              Necrologist in this team, but not by much of an edge. Tooth Fairy
              is a staple healer when it comes to Reverse 1999, though she
              doesn’t excel in this team as much as Necrologist does.
            </li>
            <li>
              <strong>Healers</strong> - As a rule of thumb, very few healers
              are restricted to particular teams, as their main role is to keep
              the team alive. It is also not uncommon for Endless teams to
              feature 2 healers, one occupying a Support slot.
            </li>
          </ul>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">PLANT TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="jessica"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="an-an-lee"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="necrologist"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="sotheby"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="bkornblume"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="support">Support</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="druvis-iii"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="sotheby"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team focused around the Plant Afflatus and Druvis’ reintroduction
            into the meta following Jessica’s release. The team is focused
            around consistency, funnily enough, providing a healthy mix of
            offense and defense while easily maneuvering around this fight’s
            mechanics.
          </p>
          <ul>
            <li>
              Jessica is here as the only 6-star Plant Carry and also a [Poison]
              inflictor in order to keep Gold from gaining its +15% DMG Dealt
              and Pen Rate buffs. The single target nature of Mane’s Bulletin
              and extra AP with a 4th person allows Jessica to more easily cycle
              her Ultimate to keep [Poison] inflicted.
            </li>
            <li>
              Joining at her side are An-an Lee and Bkornblume. An-an provides
              consistent hybrid buffing split between offense and defense, card
              upgrading for Tuning and AP-friendliness. Bkornblume instead keeps
              the boss debuffed, outputs high single-target damage and is
              surprisingly tanky under the effects of her Insight 3 in this team
              especially.
            </li>
            <li>
              Druvis may seem like an odd pick here, considering that Gold
              cannot be controlled. Druvis, however, provides something else
              here: consistent teamwide healing through her [Circle of Life]
              effect and surprising amounts of damage through her Insight 1.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Sotheby</strong> - Applicable here if you are focusing on
              a more damage oriented variant of this team. Trading Druvis’
              sustain or An-an’s buffing for Sotheby does mean Jessica has an
              easier time maximizing her single-target damage, but you’re losing
              out on defensive cornerstones that are important in Endless Mode.
            </li>
            <li>
              <strong>Necrologist</strong> - Can also be used here, though in
              place of An-an, to provide a greater +DMG Dealt buff and trade the
              -DMG Taken buff for [Prayer]’s revival effect. To note is that
              Necrologist will lose out on Druvis’ [Circle of Life] effect which
              may make her squishier than you intend her to be.
            </li>
            <li>
              <strong>6</strong> - 6 can be used in pretty much every team due
              to the versatility of his buffs, debuffs and access to
              semi-on-demand [Incantation Empowerment] for your Carry. This
              especially helps in this fight, as frequently casting Ultimates on
              the boss keeps their buffs (and damage) lowered. In addition, his
              sheer application of debuffs allow him to solo most of the
              mechanics in this fight.
            </li>
          </ul>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Gold in the Cave | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Gold in the Cave Raid!"
    game="reverse"
  />
);
